import React, { useEffect, useState, useContext, useRef } from 'react'
import { graphql } from 'gatsby'
import { SwitchTransition, CSSTransition } from "react-transition-group";
import SEO from '../components/SEO'
import Header from '../components/Header'
import Footer from '../components/Footer'
import SliderProjects from '../components/SliderProjects'
import CardProject from '../components/CardProject'
import Button from '../components/Button'
import Pagination from '../components/Pagination'
import InputSelect from '../components/InputSelect'
import SplitText from '../components/SplitText'
import { ContextLoader } from '../context';
import { detectElementsOnScreen, killObservers } from '../helpers/detectElementsOnScreen'
import smoothScrollTo from '../helpers/smoothScrollTo';
import { RichText } from 'prismic-reactjs'
import { getProjectsByFilter } from '../services/prismic'

const pageSize = 12;

const Projects = ({ data }) => {

	const [isLoading] = useContext(ContextLoader);
	useEffect(() => {
		if (isLoading === true) return
		const [observers, elements] = detectElementsOnScreen()
		return () => {
			killObservers(observers, elements)
		}
	}, [isLoading])

	const prismicNavigation = data.prismicNavigation
	const prismicFooter = data.prismicFooter
	const pageData = data.prismicProjects.data

	/*
	*	Slider projects
	*/
	const selectedProjects = pageData.selected_projects.map(p => {
		const data = p.project.document.data;
		return {
			uid: p.project.document.uid,
			image: data.image,
			title: data.title,
			location: data.location,
			characteristics: data.characteristics
		}
	});

	/*
	*	Search and filter projects
	*/
	const [filteredProjects, setFilteredProjects] = useState([])
	const [searchValue, setSearchValue] = useState('')
	const [filters, setFilters] = useState({ pageSize: pageSize })
	const [productTypesFilters, setProductTypesFilters] = useState([])
	const [colorFilters, setColorFilters] = useState([])
	const [cityFilters, setCityFilters] = useState([])
	const [systemTypeFilters, setSystemTypeFilters] = useState([])
	const [projectTypeFilters, setProjectTypeFilters] = useState([])
	const [currentPage, setCurrentPage] = useState(1)
	const [maxPage, setMaxPage] = useState(1)
	const searchResultsEl = useRef(null)
	const [showingResults, setShowingResults] = useState(false)



	useEffect(() => {
		getFilteredProjects();
	}, [])
	
	

	const getFilteredProjects = (options = {
		tags: [],
		productType: null,
		color: null,
		city: null,
		system: null,
		projectType: null,
		pageSize: pageSize,
		page: 1
	}) => {
		setCurrentPage(options.page);
		getProjectsByFilter(options).then(res => {
			handleFilteredProjectsReceived(res)
		})
	}

	const handleFilteredProjectsReceived = res => {
		const formattedRes = formatRes(res.results)
		setFilteredProjects([...formattedRes])
		setMaxPage(res.total_pages);
		setShowingResults(true)
	}

	const formatRes = res => {
		return res.map(project => {
			return {
				uid: project.uid,
				...project.data
			}
		})
	}

	const handleOnSearchBarKeyDown = e => {
		if (e.key === 'Enter') {
			onSearchEnter()
		}
	}

	const onSearchEnter = () => {
		let tags = searchValue.split(' ');
		if (tags.length === 1 && tags[0] === '') {
			tags = [];
		}
		const newFilters = filters
		filters.tags = tags
		newFilters.page = 1
		setFilters(newFilters)
		getFilteredProjects(newFilters)
	}

	const onResetEnter = () => {
		let tags = []
		if (tags.length === 1 && tags[0] === '') {
			tags = [];
		}
		const newFilters = []
		filters.tags = tags
		newFilters.productType = []
		newFilters.color = []
		newFilters.city = []
		newFilters.system = []
		newFilters.projectType = []
		newFilters.page = 1
		setSearchValue('')
		setFilters(newFilters)
		setProductTypesFilters([])
		setColorFilters([])
		setCityFilters([])
		setProjectTypeFilters([])
		setCityFilters([])
		setColorFilters([])
		setSystemTypeFilters([])
		setProjectTypeFilters([])
		getFilteredProjects(newFilters)
	}

	const handleSelectChange = (event, type) => {
		let value = Array.from(
      		event,
      		(event) => event.value
    	)

		let labels = Array.from(
			event,
			(event) => event.label
	  )

		const newFilters = filters
		switch (type) {
			case 'productType':
				newFilters.productType = value
				const productTypeFilterValues = [];
				for (let i=0; i<labels.length; i++) {
					productTypeFilterValues.push({label: labels[i], value: value[i]})
				}
				setProductTypesFilters(productTypeFilterValues)
				break;
			case 'color':
				newFilters.color = value
				const colorFilterValues = [];
				for (let i=0; i<labels.length; i++) {
					colorFilterValues.push({label: labels[i], value: value[i]})
				}
				setColorFilters(colorFilterValues)
				break;
			case 'city':
				newFilters.city = value
				const cityFilterValues = [];
				for (let i=0; i<labels.length; i++) {
					cityFilterValues.push({label: labels[i], value: value[i]})
				}
				setCityFilters(cityFilterValues)
				break;
			case 'system':
				newFilters.system = value
				const systemFilterValues = [];
				for (let i=0; i<labels.length; i++) {
					systemFilterValues.push({label: labels[i], value: value[i]})
				}
				setSystemTypeFilters(systemFilterValues)
				break;
			case 'projectType':
				newFilters.projectType = value
				const projectTypeFilterValues = [];
				for (let i=0; i<labels.length; i++) {
					projectTypeFilterValues.push({label: labels[i], value: value[i]})
				}
				setProjectTypeFilters(projectTypeFilterValues)
				break;
			default:
				break;
		}
		newFilters.page = 1
		setFilters(newFilters)
		getFilteredProjects(filters)
	}

	const onPaginationPageSelected = selectedPage => {
		const newFilters = filters
		newFilters.page = selectedPage
		setFilters(newFilters)
		getFilteredProjects(newFilters)
		smoothScrollTo(searchResultsEl.current, -300)
	}

	// TO DO: put this in helper file?
	/* Select options for filters dropdowns */
	const parseSelectOptions = (arr) => {
		const newArr = arr.map(item => {
			return {
				label: item.value,
				value: item.value
			}
		})
		return newArr;
	}

	const selectsOptionsParsed = {
		productTypes: parseSelectOptions(pageData.products_types_list),
		colors: parseSelectOptions(pageData.colors_list),
		cities: parseSelectOptions(pageData.cities_list),
		systems: parseSelectOptions(pageData.systems_list),
		projectTypes: parseSelectOptions(pageData.projects_types_list)
	}

	let resultsSection

	if (filteredProjects.length > 0 || !showingResults) {
		resultsSection = 
			<ul className="Projects__SearchResultsContainer">
				{filteredProjects.map((project, index) => (
					<li
						className="Projects__SearchResultsItem"
						key={`filtered-project-${project.uid}`}
					>
						<CardProject project={project} />
					</li>
				))}
			</ul>
	} else {
		resultsSection = 
			<div>
				<h2 className="search-no-results">No projects found</h2>
				<p className="search-no-results-body body-top">We couldn’t find any projects that matched the filters provided.</p>
				<p className="search-no-results-body">Try adjusting filters or resetting the search.</p>
			</div>
	}

	const searchFilters = 
		<div className="Projects__SearchFilters">
			<div className="Projects__SearchFilterContainer">
				<span className="Projects__SearchFilterLabel">
					Product Type
				</span>
				<InputSelect
					placeholder="All"
					className="input-select--product-type"
					options={selectsOptionsParsed.productTypes}
					isMulti={true}
					value={productTypesFilters}
					onChange={e => handleSelectChange(e, 'productType')}
				/>
			</div>
			<div className="Projects__SearchFilterContainer">
				<span className="Projects__SearchFilterLabel">
					Color
				</span>
				<InputSelect
					placeholder="All"
					options={selectsOptionsParsed.colors}
					isMulti={true}
					value={colorFilters}
					onChange={e => handleSelectChange(e, 'color')}
				/>
			</div>
			<div className="Projects__SearchFilterContainer">
				<span className="Projects__SearchFilterLabel">
					City
				</span>
				<InputSelect
					placeholder="All"
					options={selectsOptionsParsed.cities}
					isMulti={true}
					value={cityFilters}
					onChange={e => handleSelectChange(e, 'city')}
				/>
			</div>
			<div className="Projects__SearchFilterContainer">
				<span className="Projects__SearchFilterLabel">
					System
				</span>
				<InputSelect
					placeholder="All"
					options={selectsOptionsParsed.systems}
					isMulti={true}
					value={systemTypeFilters}
					onChange={e => handleSelectChange(e, 'system')}
				/>
			</div>
			<div className="Projects__SearchFilterContainer">
				<span className="Projects__SearchFilterLabel">
					Project Type
				</span>
				<InputSelect
					placeholder="All"
					options={selectsOptionsParsed.projectTypes}
					isMulti={true}
					value={projectTypeFilters}
					onChange={e => handleSelectChange(e, 'projectType')}
				/>
			</div>
		</div>


	const pageTitle = "Architectural Terracotta Rainscreens | Shildan Group";
	const metaDescription = "We help visionaries turn sustainable design ideas into reality with terracotta rainscreens. Find out how you can do the same with architectural terracotta.";
	const metaKeywords = "Architectural terracotta, terracotta rainscreen";

  return (
    <div>
      <SEO title={pageTitle} description={metaDescription} keywords={metaKeywords} />
			<Header currentPage="projects" navigation={prismicNavigation} footer={prismicFooter} />

			<section className="Projects">

				<h1
					className="Projects__Title"
					data-is-on-screen
				>
					<SplitText splitBy="lines" hasMask>
						<RichText render={pageData.banner_title.raw} />
					</SplitText>
				</h1>

				<SliderProjects selectedProjects={selectedProjects} />

				<div className="Projects__Description">
					<RichText render={pageData.description.raw} />
				</div>

				<section className="Projects__Search">

					<h3 className="Projects__SearchTitle">
						{pageData.section_search_title.text}
					</h3>

					<div className="Projects__SearchBarContainer">
						<div className="Projects__SearchBarWrapper">
							<svg className="Projects__SearchBarIcon" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.715 2.665c3.62-3.553 9.485-3.553 13.106 0a8.977 8.977 0 01.897 11.848l4.88 4.791a1.329 1.329 0 010 1.902 1.388 1.388 0 01-1.935 0l-4.882-4.792c-3.628 2.643-8.775 2.35-12.066-.88a8.981 8.981 0 010-12.869zm1.759 1.727a6.572 6.572 0 000 9.414c2.646 2.598 6.94 2.598 9.587 0a6.571 6.571 0 000-9.414c-2.647-2.599-6.94-2.599-9.587 0z" fill="#fff"/></svg>
							<input
								type="search"
								className="Projects__SearchBar"
								placeholder="Search Projects"
								onKeyDown={handleOnSearchBarKeyDown}
								value={searchValue}
								onChange={e => setSearchValue(e.target.value)}
							/>
						</div>
						<Button
							className="Projects__SearchBarSubmit"
							modifier="small"
							onClick={onSearchEnter}
						>
							Search
						</Button>
						<Button
							className="Projects__SearchBarReset"
							modifier="small"
							onClick={onResetEnter}
						>
							Reset
						</Button>
					</div>

					{searchFilters}

					<div
						className="Projects__SearchResults"
						ref={searchResultsEl}
					>
						<SwitchTransition mode="out-in">
							<CSSTransition
								key={filteredProjects}
								timeout={200}
							>
								{resultsSection}
							</CSSTransition>
						</SwitchTransition>
					</div>

					<Pagination
						current={currentPage}
						max={maxPage}
						onPageSelected={onPaginationPageSelected}
					/>
				</section>

			</section>

			<Footer footer={prismicFooter.data} hasBorder={true} showImage={false} />

    </div>
  )
}

export const query = graphql`
	query Projects {
		prismicProjects {
			data {
				banner_title {
					raw
				}
				description {
					raw
				}
				section_search_title {
					text
				}
				selected_projects {
					project {
						document {
							... on PrismicSelectedProject {
								id
								uid
								data {
									title {
										text
									}
									location {
										text
									}
									image {
										alt
										fluid {
											src
											srcSet
										}
									}
									characteristics {
										label {
											text
										}
										content {
											text
										}
									}
								}
							}
						}
					}
				}
				products_types_list {
					value
				}
				colors_list {
					value
				}
				cities_list {
					value
				}
				systems_list {
					value
				}
				projects_types_list {
					value
				}
			}
		}
		prismicNavigation {
      ...HeaderQuery
    }
		prismicFooter {
			...FooterQuery
		}
	}
`

export default Projects